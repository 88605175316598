import 'animate.css'
import '../../../assets/icon/font-icon.js'
import Header from '../common/Header.vue'
import Footer from '../common/Footer.vue'
import Pagination from '../../../components/Pagination.vue'
import { ElCarousel, ElCarouselItem, ElTabs, ElTabPane, ElCard, ElDialog, ElSelect, ElOption, ElForm, ElFormItem, ElInput } from 'element-plus'
export default {
    components: {
        Header: Header,
        Footer: Footer,
        Pagination: Pagination,
        [ElCarousel.name]: ElCarousel,
        [ElCarouselItem.name]: ElCarouselItem,
        [ElTabs.name]: ElTabs,
        [ElTabPane.name]: ElTabPane,
        [ElCard.name]: ElCard,
        [ElDialog.name]: ElDialog,
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        [ElForm.name]: ElForm,
        [ElFormItem.name]: ElFormItem,
        [ElInput.name]: ElInput
    },
    data () {
        return {
            applyState: false,
            regionCode: '610000',
            regionName: '陕西省',
            years: '',
            develops: [],
            ruleForm: {
                name: '',
                phone: '',
                comment: ''
            },
            rules: {
                name: [
                    { required: true, message: '请填写姓名', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请填写电话号码', trigger: 'blur' }
                ],
                comment: [
                    { required: true, message: '请填写您的业务的简要描述', trigger: 'blur' }
                ]
            }
        }
    },
    mounted () {
        this.color_m = window.const.global.HRP_COLOR_M
        this.color_s1 = window.const.global.HRP_COLOR_S1
        this.b1show = true
    },
    methods: {
        bannerChange (val) {
            if (val === 0) {
                this.b1show = true
                this.b2show = false
            } else {
                this.b1show = false
                this.b2show = true
            }
        },
        developCallback (data) {
            this.develops = data
        },
        regionSearch (regionCode, regionName) {
            this.regionCode = regionCode
            this.regionName = regionName
            this.search()
        },
        yearsSearch (years) {
            this.years = years
            this.search()
        },
        submitMessage () {
            if (this.ruleForm.name === '') {
                this.$alert('请填写姓名', '提示信息')
            } else if (this.ruleForm.phone === '') {
                this.$alert('请填写手机号码', '提示信息')
            } else {
                this.$alert('提交成功请耐心等待', '提示信息').then(() => {
                    this.applyState = false
                })
            }
        },
        async search () {
            var pd = { regionCode: this.regionCode, years: this.years }
            this.$refs.developPage.cusSearchPage(this.BMS_URL + '/stats/developAPC/retrieveDevelopPage', pd, { size: 15 })
        }
    }
}
