<template>
  <div class="container">
    <Header active="about" />
    <el-carousel class="banner" @change="bannerChange" interval="6000">
      <el-carousel-item>
        <div class="item">
          <img
            class="img"
            :src="require('../../../assets/images/website/about.jpg')"
          />
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="main">
      <el-tabs
        tab-position="left"
        active-color="#fcb316"
        style="height: 1100px"
      >
        <el-tab-pane label="合作专家招募">
          <div class="cnt">
            作为中国非官方智库里资料和数据最完善的智库，以维度作为视角，以数据作为依据，遵循社会、经济、科学、自然的运行规律，给决策者提供全景的视角展现，圆润其思维逻辑，梳理其执行路径，完善其团队精神，清晰其目标核心。
            因为业务需要，诚聘各类专家<br />
            <span class="fc-red"
              >待遇：被聘用的专家将被展现在智库官网，根据专家需求配合其出专著、做课题、参加论坛、有酬参与其专业范围内的业务和活动。<br />
              酬劳：根据专家等级，按劳取筹。<br />
              有意向者欢迎提交简历和业绩证明，将会一一邀约拜访签约。<br />
              联系人：赵民强<br />
              电话：15029291243 <br />
            </span>
            <strong>专家统一要求</strong><br />
            1、年龄35周岁以上。 <br />
            2、有丰富的专业案例背书和从业经历。<br />
            3、具备专业文案书写能力。 <br />
            <strong>分类专家要求</strong> <br />
            研发类专家： <br />
            1、硕士及以上学历学位。<br />
            2、在国际国内权威期刊发表过不少于5篇论文。 <br />
            经管类专家：<br />
            1、专科以上学历。<br />
            2、具备经济、管理、市场、人资等方面实际工作经验，并有成功案例。<br />
            3、有成熟的专业体系。 <br />
            4、具备2个行业或3家企业以上的从业经历。<br />
            职能类专家：（财务、法律、专业技能类等）<br />
            1、具备从业资格和高级职称或高级技能。<br />
            2、具备经典案例。<br />
            金融类专家：<br />
            1、具备本行业8年以上从业经验。<br />
            2、具备货币、汇率、利率或产业兼并投资融资上市股改等研究和实战业绩。<br />
            政策类专家：<br />
            1、熟悉国内国际政治形式。<br />
            2、有自己的完善的理论体系和鲜明观点。<br />
            资料模版：<br />
            证件照+申请专家类型+专业领域+简历+成果列表和资料+联系方式<br />
            社科类专家：<br />
            1、在人文、心理等社会科学方面有所建树研究。<br />
            2、具有实践工作经验。
          </div>
        </el-tab-pane>
        <el-tab-pane label="人员招聘"
          ><div class="cnt">
            <strong>招聘岗位：</strong>VIP客户服务专员<br />
            <strong>岗位描述：</strong>
            负责日常与VIP客户的业务沟通，关系维护，编写业务文档，邀约和组织相关专家进行VIP客户的业务分析及处理<br />
            <strong>职位福利：</strong
            >五险一金、年底双薪、绩效奖金、弹性工作、节日福利、周末双休、每年多次调薪、带薪年假<br />
            <strong>招聘条件：</strong><br />
            1、985、211学校硕士及以上学历学位。<br />
            2、有3年以上工作经验。<br />
            3、有超强得意志和专注力。<br />
            4、具备专业文案书写能力。<br />
            5、具备实地和文卷调研能力。<br />
            6、具备数据工具使用能力。<br />
            7、具备社交公关能力。<br /><br />
            <strong
              ><span class="fc-red"
                >简历投递邮箱：jiuweizhiku@126.com</span
              ></strong
            >
            <br /><br /><br /><br />
            <strong>招聘岗位：</strong>AI算法工程师<br />
            <strong>岗位描述：</strong>
            负责根据实际业务需求设计和修改AI算法模型，采集数据进行模型训练，并对结果数据进行分析和验证<br />
            <strong>职位福利：</strong
            >五险一金、年底双薪、绩效奖金、弹性工作、节日福利、周末双休、每年多次调薪、带薪年假<br />
            <strong>招聘条件：</strong><br />
            1、985、211学校博士或在读博士。<br />
            2、有8年以上工作经验。<br />
            3、熟悉机器学历和深度学习相关算法的基本原理和应用<br />
            4、熟练使用java/python/c#等开发语言<br />
            5、熟悉flink/spark/hadoop/hbase/hive等分布式计算技术，熟悉其运行机制和体系结构;
            <br /><br />
            <strong
              ><span class="fc-red"
                >简历投递邮箱：jiuweizhiku@126.com</span
              ></strong
            ><br /></div
        ></el-tab-pane>
        <el-tab-pane label="联系我们">
          <div class="link">
            <el-row :gutter="30">
              <el-col :span="8">
                <el-card class="item">
                  <div class="name">公司地址</div>
                  <div class="content">
                    陕西省西安市高新区科技路48号创业广场C座1层
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card class="item">
                  <div class="name">联系电话</div>
                  <div class="content">手机：15029291243<br /><br /></div>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card class="item">
                  <div class="name">电子邮箱</div>
                  <div class="content">
                    jiuweizhiku@126.com<br />
                    jiuweizhiku-c@126.com
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="submit">
            <el-row>
              <el-col :span="12">
                <img
                  class="img"
                  :src="
                    require('../../../assets/images/website/about/contact.svg')
                  "
                />
              </el-col>
              <el-col :span="12">
                <div class="name">填写信息给我们留言</div>
                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <el-form-item label="姓名" prop="name">
                    <el-input
                      v-model="ruleForm.name"
                      placeholder="请输入您的姓名"
                    ></el-input> </el-form-item
                  ><el-form-item label="电话号码" prop="phone">
                    <el-input
                      v-model="ruleForm.phone"
                      placeholder="请输入您的手机号码"
                    ></el-input> </el-form-item
                  ><el-form-item label="业务备注" prop="comment">
                    <el-input
                      type="textarea"
                      placeholder="请输入您要咨询业务的简要备注"
                      v-model="ruleForm.comment"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      icon="el-icon-chat-dot-round"
                      type="primary"
                      @click="submitMessage"
                      >提交留言</el-button
                    >
                  </el-form-item></el-form
                >
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <Footer />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
